.user-tile {
  .head-text {
    flex-direction: row;
    align-items: center;
    img {
      height: 40px;
      width: 40px;
      border-radius: 50%;
      margin-right: 10px;
    }
  }
  span:last-child {
    font-size: inherit;
    font-weight: normal;
  }
}

.user-tile:hover {
  box-shadow: var(--shadow) !important;
  background: var(--background) !important;
}
