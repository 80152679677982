.empty-add{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
    flex-direction: column;
    .text{
        font-size: 25px;
        padding: 50px;
    }
    .xx{
        margin-bottom: 20px;
    }
    .btns{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        font-weight: bold;
    }
}