.ip-bubble {
  width: 100%;
  .ip-head {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .neo-select {
      width: 300px;
    }
  }
  .timeline {
    margin-top: 10px;
  }
}

.neo-base {
  flex: 20;
  display: flex;
  flex-direction: column;
  .neo-legend {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .quater {
      flex: 1;
      color: #273850;
      font-size: 14px;
      // font-family: Calibri;
    }
  }
}

.ip-bubble .timeline {
  position: relative;
  height: 60vh;
  width: 100%;
  background: var(--background);
  // box-shadow: 6px 6px 18px #d1d3d8,
  //   -9px -8px 20px 2px rgba(209, 211, 216, 0.14902);
  display: flex;
  border: 1px solid var(--gray2);
  .internal-flexbox:last-child {
    border: none !important;
  }
  .internal-flexbox {
    flex: 1;
  }

  .internal-flexbox {
    border-right: solid 1px var(--gray2);
  }
}
