@import url("https://fonts.googleapis.com/css2?family=GFS+Didot&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");

@import "./variables.scss";

body {
  // background-image: url(../assets/images/app-bg.png);
  background-size: cover;
  font-family: "Lato", sans-serif;
  width: 100%;
  background-color: var(--background);
  color: var(--text-color);
}

a {
  color: var(--primary-color);
}

#app {
  height: 100%;
  // width: 100wh;
  // background: rgba(245, 246, 250, 0.95);
  // background: rgba(230, 247, 255, 0.13);
  // background: linear-gradient(90deg, rgba(245, 246, 250, 0.91) 0%, rgba(225, 229, 239, 0.96) 100%);

  // background: #f5f6fa;
  // background: linear-gradient(323deg, rgba(225, 229, 239, 1) 0%, rgba(245, 246, 250, 0.94) 100%);
}
.admin-container {
  display: flex;
  // justify-content: center;
  // align-items: center;
  // padding: 50px 30px 0 30px;
  padding: 50px 0 0 0;
  // width: 1366px;
  margin: auto;
  height: 93vh;
  padding: 0;
  padding-top: 40px;
  position: relative;
}

.customer-portal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 0 0 0;
  width: 1366px;
  margin: auto;
}

input.neo {
  border-radius: 104px;
  border-radius: 0;
  min-width: 250px;
  width: 100%;
  outline: none;
  padding-left: 21px;
  padding-left: 8px;
  border-radius: 4px;
}

textarea.neo {
  border: none;
  border-radius: 20px;
  background: #eaedf3;
  box-shadow: inset 15px 15px 19px #dadce2, inset -15px -15px 19px #fafeff;
  height: 140px;
  min-width: 250px;
  width: 100%;
  outline: none;
  padding-left: 20px;
  padding-top: 20px;
  color: #273850;
  font-size: 16px;
}

.neo-select {
  border: none;
  border-radius: 104px;
  // border-radius: 89px;
  // background: #EAEDF3;
  // box-shadow: inset 15px 15px 19px #dadce2, inset -15px -15px 19px #fafeff;
  border-radius: 53px;
  background: #ebeef4;
  box-shadow: 10px 10px 20px #c8cacf, -10px -10px 20px rgba(255, 255, 255, 0.42);
  height: 50px;
  min-width: 250px;
  width: 100%;
  outline: none;
  padding-left: 21px;
  color: #273850;
  font-size: 16px;
  .ant-select-selector {
    background-color: transparent !important;
    border: none !important;
  }
  .ant-select-selection-placeholder,
  .ant-select-selection-item {
    margin-top: 9px;
    min-height: 100px;
  }
  text-align: left;
}
.ant-select-focused.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: none;
}

.welcome-subtext {
  text-align: center;
  font-size: 20px;
  font-weight: 100;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  color: #273850;
  font-weight: 300;
}

.devider {
  margin: 40px 0px 40px 0px;
  border-top: none;
  border-bottom: solid 1px rgba(153, 168, 191, 0.5);
}

.devider-quest {
  border-top: none;
  border-bottom: solid 1px rgba(153, 168, 191, 0.5);
}

.project-form {
  display: flex;
  /* justify-content: space-evenly; */
  margin-top: 40px;
  justify-content: center;
  .ant-form-item {
    margin-right: 30px;
  }
}

.ant-form-item-label > label {
  margin-top: 10px;
}

.checkbox-row {
  padding: 10px 0 10px 0;
  .ant-checkbox-wrapper {
    font-size: 16px;
    color: #273850;
  }
  .ant-checkbox {
    .ant-checkbox-inner {
      border-radius: 5px;
      background: #f6f6fa;
      box-shadow: inset 3px 3px 6px #c5c5c8, inset -3px -3px 6px #ffffff;
      width: 30px;
      height: 30px;
    }
  }
  .ant-checkbox-checked {
    .ant-checkbox-inner {
      border-radius: 5px !important;
      background: #f6f6fa;
      box-shadow: 5px 5px 10px #c5c5c8, -5px -5px 10px #ffffff;
      width: 30px;
      height: 30px;
      border: none;
    }
  }

  .ant-checkbox-inner::after {
    left: 32%;
    width: 8.714286px;
    height: 13.142857px;
  }
  .ant-checkbox-checked .ant-checkbox-inner:hover,
  .ant-checkbox-checked .ant-checkbox-inner::after {
    border-left: none;
    border-right: 3px solid #faad14 !important;
    border-top: none;
    border-bottom: 3px solid #faad14 !important;
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border: none;
  }
  .ant-checkbox-checked::after {
    border: none;
  }
  .ant-checkbox-wrapper {
    span:nth-child(1) {
      float: left;
    }
    span:nth-child(2) {
      display: flex;
    }
  }
}

.ant-switch-checked {
  background-color: $primary-color;
}

.button-div {
  display: flex;
  justify-content: center;
  align-items: center;
  a {
    color: #575f6b;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    padding-top: 20px;
    line-height: 34px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.app-first-container {
  // display: flex;
  // flex-direction: column;
  // width: 100%;
  // // height: 100vh;
}

.super-admin-dashboard {
  // width: 1366px;
  // margin: auto;
  // padding-top: 50px;
}
.cusomer-admin-dashboard {
  width: 1366px;
  margin: auto;
  padding-top: 0;
}
.ds-head {
  color: #273850;
  font-size: 30px;
  margin: 10px 0 10px 0;
}
.text-q {
  color: var(--text-color);
  font-size: 16px;
}
/*
// Radio Button Style Reset
.ant-radio-inner {
  width: 20px;
  height: 20px;
  border-radius: 50px;
  background: #f6f7fa;
  box-shadow: inset 2px 2px 4px #d1d2d5, inset -2px -2px 4px #ffffff;
}


.ant-radio-checked .ant-radio-inner {
  border-color: transparent !important;
  border-radius: 50px;
  background: #f6f7fa;
  box-shadow: 2px 2px 4px #d1d2d5, 2px 1px 4px #ffffff;
}
.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
  border-color: transparent !important;
}
.ant-radio-inner::after {
  width: 10px;
  height: 10px;
  top: 4px;
  left: 4px;
  background-color: var(--primary-color) !important;
}
.ant-radio-checked::after {
  border: 1px solid var(--primary-color) !important;
}
*/
.ant-progress-bg {
  border-radius: 10px;
  box-shadow: -1px 2px 6px 0 var(--primary-color);
  background-image: linear-gradient(to right, #fea565 -7%, #fb7900 95%);
}

.ant-progress-inner {
  background-color: #ded6d6;
}
.large-progress {
  .ant-progress-text {
    display: none;
    font-weight: 500;
  }
  .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
    stroke: #8dc63f;
    box-shadow: 0 3px 5px 0 rgba(77, 255, 0, 0.42);
  }
}
.ant-progress-text {
  font-weight: 500;
  color: var(--text-color) !important;
}

.progress-green {
  .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
    stroke: #8dc63f;
    box-shadow: 0 3px 5px 0 rgba(77, 255, 0, 0.42);
  }
}

.small-progress {
  position: absolute;
  margin-left: -90px;
  margin-top: 10px;
  .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
    stroke: #ff2e77;
    box-shadow: 0 3px 5px 0 rgba(255, 46, 119, 0.71);
  }
}

.maa {
  flex: 2.5;
  min-height: 70vh;
  flex-direction: row;
  display: inline-flex;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 36px;
  margin-top: 30px;
}

@media only screen and (max-width: 1024px) {
  .maa {
    justify-content: center;
  }
  .orange-head {
    margin-left: 50px;
  }
}

.maa-final {
  display: flex;
  height: 56vh;
  width: 350px;
  border-radius: 16px;
  border-radius: 16px;
  background: linear-gradient(145deg, #e6eaf2, #f5f5f9);
  box-shadow: 7px 7px 31px #c4c5c7, -7px -7px 31px rgba(255, 255, 255, 0);
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.ant-progress-circle-trail {
  stroke: var(--gray2);
}

.md-modal {
  width: 800px !important;
  .ant-modal-header {
    background: var(--background);
    border: none;
    border-radius: 4px;
    .ant-modal-title {
      color: var(--text-color);
      font-size: 16px;
    }
  }
  .ant-modal-content {
    border-radius: 4px;
    background: var(--background);
  }
  .ant-modal-body {
    display: flex;
    flex-direction: column;
  }
  .ant-modal-footer {
    display: none;
  }
}

.md-modal-x {
  .ant-modal-header {
    background: var(--background);
    border: none;
    border-radius: 4px;
    .ant-modal-title {
      color: var(--text-color);
      font-size: 16px;
    }
  }
  .ant-modal-content {
    border-radius: 4px;
    background: var(--background);
  }
  .ant-modal-body {
    display: flex;
    flex-direction: column;
  }
  .ant-modal-footer {
    border-top: 1px solid var(--border);
  }
}

.lg-modal {
  width: 1276px !important;
  .ant-modal-header {
    background: var(--background);
    border: none;
    border-radius: 4px;
    .ant-modal-title {
      color: var(--text-color);
      font-size: 16px;
    }
  }
  .ant-modal-content {
    border-radius: 4px;
    background: var(--background);
  }
  .ant-modal-body {
    display: flex;
    flex-direction: column;
  }
  .ant-modal-footer {
    display: none;
  }
}

.md-top-area {
  display: flex;
  flex-direction: row;
}
.md-progres {
  flex: 1;
  text-align: center;
}
.md-label {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.md-line {
  border-bottom: solid 1px var(--gray2);
  margin: 20px 0 20px 0;
}

.ant-modal-close {
  color: var(--text-color);
}

.antd-button-default {
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
}

.score-round-button {
  width: 31px;
  height: 31px;
  box-shadow: var(--shadow);
  background-color: var(--gray2);
  border-radius: 50%;
  color: var(--text-color);
  display: flex;
  align-items: center;
  justify-content: center;
}

.spider-chart {
  svg {
    // -webkit-filter: drop-shadow(3px 3px 2px rgba(212, 206, 206, 0.7));
    // filter: drop-shadow(3px 3px 2px rgba(212, 206, 206, 0.7));
    -webkit-filter: none;
    filter: none;
    .scale:nth-child(1),
    .scale:nth-child(3) {
      fill: var(--background);
    }
    .scale:nth-child(2),
    .scale:nth-child(4) {
      fill: var(--gray2);
    }
    .caption {
      fill: var(--text-color);
    }
  }
}

.extra-bottom {
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
}

.project-form-con {
  height: 66vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.com-logo {
  height: 100px;
  width: 100px;
  border: 1px solid rgb(209, 209, 209);
  border-radius: 50%;
  padding: 12px;
  background-image: linear-gradient(to bottom, #ffffff, #f2f2f2);
  float: left;
  display: flex;
  align-items: center;
  justify-content: center;
}

.org-table {
  .ant-table-thead {
    tr {
      th {
        border-bottom: 1px solid #e1e5ef;
      }
    }
  }
  a {
    color: var(--primary-color);
  }
}
.ant-btn:hover {
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
}
.ant-btn-primary,
.ant-btn-primary:hover,
.ant-btn-primary:focus,
.ant-btn-primary:active {
  background-color: var(--primary-color);
  border: none;
  color: #fff;
}

.wd-1366 {
  width: 1366px;
  margin: auto;
}

.company-home {
  padding-top: 0;
}
.new-project {
  width: 1366px;
}

.no-result {
  .ant-empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 70vh;
  }
}

.mxx {
  min-height: 45vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.font-head {
  color: #ff9e00;
  font-size: 22px;
  font-weight: bold;
}

.orange-head {
  color: #ff9e00;
  font-size: 25px;
}

.ant-modal-title {
  text-transform: uppercase;
  font-weight: bold;
}

/*
.ma-slider {
  .ant-slider-mark-text:nth-child(1) {
    left: 7% !important;
  }
  .ant-slider-mark-text:nth-child(2) {
    // left: 28% !important;
  }
  .ant-slider-mark-text:nth-child(3) {
    // left: 60% !important;
  }
  .ant-slider-mark-text:nth-child(4) {
    // left: 82% !important;
  }
  .ant-slider-mark-text:nth-child(5) {
    left: 95% !important;
    width: max-content;
  }
  .ant-slider-mark-text {
    color: #0e1626;
    top: 10px;
    font-size: 14px;
  }

  .ant-radio-wrapper {
    color: #0e1626;
  }

  .ant-slider-track {
    position: absolute;
    height: 9px;
    border-radius: 10px;
    -webkit-transition: background-color 0.3s;
    transition: background-color 0.3s;
    box-shadow: -1px 2px 4px 0 var(--primary-color);
    background-image: linear-gradient(to right, #fea565 -7%, #fb7900 95%);
    top: 3px;
  }
  .ant-slider-rail {
    box-shadow: inset 2px 5px 10px rgba(88, 107, 137, 0.28);
    height: 9px;
  }
  .ant-slider-handle {
    background-image: url("../assets/icons/SliderButton.svg");
    border: none;
    background-color: transparent;
    background-size: cover;
    height: 100px;
    width: 100px;
    position: absolute;
    top: -38px;
  }
  .ant-slider-dot {
    // border-color: transparent !important;
    // background-color: transparent !important;
  }
  .ant-slider-dot {
    position: absolute;
    background-color: #fff;
    width: 10px;
    height: 10px;
    top: -1px;
  }
  .ant-slider-dot:first-child {
    margin-left: -1px;
  }

  .ant-slider-dot-active {
    border-color: #fff;
  }
  .ant-slider-handle:focus {
    box-shadow: none !important;
  }
}

*/

.ant-slider-mark {
  .ant-slider-mark-text:first-child {
    margin-left: 45px;
  }
}
.ant-slider-handle {
  border: 2px solid var(--primary-color);
}

.ant-slider-track {
  background: var(--primary-color);
}

.ant-slider-mark-text {
  color: var(--text-color);
}

.q-content-area {
  padding: 0 20px 0 20px;
  min-height: 41vh;
  max-height: 437px;
  overflow-y: scroll;
  .q-row {
    margin-bottom: 40px;
  }
  .q-row:last-child {
    margin-bottom: 0 !important;
  }
}

.react-loading-skeleton {
  border-radius: 10px;
}

#printArea {
  display: flex;
  visibility: hidden;
  flex-direction: row;
  font-family: sans-serif;
}

@media print {
  .maa {
    flex: 1;
  }
  #resultArea {
    margin-left: 40px;
  }
  .orange-head {
    margin-left: 40px;
  }
  .botx-area-p,
  .prtx {
    display: none;
  }
  body {
    background-image: none !important;
    background-color: #ebeef4 !important;
  }
}
.md-modal-px {
  width: 1000px !important;
  .md-progres {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    text-align: center;
  }
  .mtxx {
    margin-left: 52px;
    margin-top: 30px;
  }
}

.mark {
  position: absolute;
}

.mark0 {
  left: 705px;
  bottom: calc(180px + 40px);
}

.mark1 {
  left: 733px;
  bottom: calc(197px + 40px);
}

.mark2 {
  left: 762px;
  bottom: calc(211px + 40px);
}

.mark3 {
  left: 798px;
  bottom: calc(221px + 40px);
}

.mark4 {
  left: 826px;
  bottom: calc(230px + 40px);
}

.mark5 {
  left: 853px;
  bottom: calc(235px + 40px);
}

.mark6 {
  left: 885px;
  bottom: calc(252px + 40px);
}

.mark7 {
  left: 908px;
  bottom: calc(265px + 40px);
}

.mark8 {
  left: 927px;
  bottom: calc(299px + 40px);
}

.mark9 {
  left: 939px;
  bottom: calc(317px + 40px);
}

.mark10 {
  left: 953px;
  bottom: calc(325px + 40px);
}

.notification-sent {
  margin: 0;
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 20px;
  background: #036d1e23;
  justify-content: center;
  margin-bottom: 20px;
  border-radius: 6px;
  span {
    color: #426f2b;
  }
}

.ant-table-row {
  cursor: pointer;
}

.neo-collaspe {
  height: 54vh;
  overflow-y: scroll;
  width: 321px;
}

/*
 *  STYLE 2
 */

#style-2::-webkit-scrollbar-track {
  //  border-radius: 10px;
  //  background-color: rgba(88, 108, 138, 0.1)
  background-image: url("../assets/images/slider-bar.png");
  background-repeat: repeat-y;
  background-position: center;
}

#style-2::-webkit-scrollbar {
  /* margin-left: 46px; */
  width: 38px;
}

#style-2::-webkit-scrollbar-thumb {
  background-image: url("../assets/images/Slider button.png");
  background-size: 100px;
  background-repeat: no-repeat;
  background-position: top;
  height: 300px;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  // height: 50px;
}
.ant-picker {
  // border: none;
  // border-radius: 104px;
  // border-radius: 50px;
  // background: #eaedf3;
  // box-shadow: inset 15px 15px 19px #dadce2, inset -15px -15px 19px #fafeff;
  // height: 50px;
  // min-width: 250px;
  // width: 100%;
  // outline: none;
  // padding-left: 21px;
  // color: #273850;
  // font-size: 16px;
}
.cursor-pointer {
  cursor: pointer;
}
.adv-btn {
  margin-left: 68px;
}
.rotate-90 {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.ant-table-cell {
}
.small-select {
  width: 50%;
}

.ant-message-notice-content {
  position: absolute;
  right: 30px;
  top: 58px;
  padding: 0;
}

.ant-message-success {
  color: #fff;
  padding: 10px 16px;
  border-radius: 5px;
  background: #079507;
}

.ant-message-error {
  color: #fff;
  padding: 10px 16px;
  border-radius: 5px;
  background: #fe5b02;
}

.ant-message-info,
.ant-message-loading {
  color: #000;
  padding: 10px 16px;
  border-radius: 5px;
  background: #fff;
}

h1 {
  font-family: "GFS Didot";
  font-size: 46px;
  color: var(--text-color);
}

p {
  color: var(--text-color);
}

.ant-menu-horizontal {
  background: var(--background);
}

.ant-menu {
  color: var(--text-color);
}

.ant-dropdown-trigger {
  span {
    color: var(--text-color) !important;
  }
}

.sub-page {
  padding: 25px 60px 39px 60px;
}
.ant-form-item-control-input-content {
  width: 500px;
}

button.outline {
  background: transparent;
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
}
button.outline:hover {
  background: transparent;
}

.ant-form-item-label label {
  color: var(--text-color);
}

.ant-form-item-explain {
  color: var(--gray1);
}

.ant-breadcrumb-link {
  a {
    color: var(--gray1);
  }
  color: var(--text-color);
}
.ant-breadcrumb {
  .ant-breadcrumb-separator {
    color: var(--text-color);
  }
}

.projects-page {
  padding: 25px 60px 39px 60px;

  h1 {
    font-family: "GFS Didot";
    font-size: 46px;
    margin-bottom: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .subtxt {
    color: var(--text-color);
  }
  .numbers {
    margin-top: 20px;
    display: flex;
    .number-tile {
      margin-right: 20px;
    }
  }
  .projects {
    padding-top: 40px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-height: 600px;
    overflow-y: scroll;
  }
}

.projects-loading {
  display: flex;
}

.ant-checkbox + span {
  color: var(--text-color);
  font-size: 14px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "GFS Didot";
  color: var(--text-color);
}

.ant-btn-default {
  background: var(--background);
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
}
.ant-btn-default:hover {
  background: var(--primary-color);
  color: var(--background);
}

.ant-popover-inner {
  background: var(--background);
  box-shadow: var(--shadow);
  .ant-popover-message {
    color: var(--text-color);
  }
}

.action-icons {
  .anticon:hover {
    color: var(--primary-color);
  }
}

label {
  color: var(--text-color);
}

.ant-input {
  background: var(--placeholder-background) !important;
  border: 1px solid var(--border);
  padding-top: 3px;
  padding-bottom: 5px;
  color: var(--text-color);
}
.ant-select-selector {
  border: 1px solid var(--border) !important;
  background: var(--placeholder-background) !important;
  border: 1px solid var(--border);
  color: var(--text-color);
}
.ant-picker {
  background: var(--placeholder-background) !important;
  border: 1px solid var(--border);
  padding-top: 3px;
  padding-bottom: 5px;
  color: var(--text-color);
}

.ant-select-selection-placeholder {
  color: var(--border) !important;
}

.ant-input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  // color: red;
  opacity: 1;
  font-size: 14px;
  color: var(--border);
}

.ant-picker-input input {
  color: var(--text-color);
}

.ant-select-selection-item-content {
  color: var(--text-color);
}
.ant-select-multiple {
  .ant-select-selection-item {
    background: var(--gray2);
    border: var(--border);
  }
}

.ant-menu-item {
  a {
    color: var(--text-color) !important;
  }
}
.ant-menu-item-selected {
  a {
    color: var(--primary-color) !important;
  }
}

.ant-skeleton.ant-skeleton-active .ant-skeleton-avatar,
.ant-skeleton.ant-skeleton-active .ant-skeleton-button,
.ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-paragraph > li,
.ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-title,
.ant-skeleton.ant-skeleton-active .ant-skeleton-image,
.ant-skeleton.ant-skeleton-active .ant-skeleton-input {
  // background: linear-gradient(90deg, var(--gray2) 25%, #e6e6e6 37%, #f2f2f2 63%);
  background: linear-gradient(90deg, var(--border) 25%, var(--gray2) 37%, var(--border) 63%);
  background-size: 400% 100%;
  -webkit-animation: ant-skeleton-loading 1.4s ease infinite;
  animation: ant-skeleton-loading 1.4s ease infinite;
}

.three-sub {
  font-weight: 400;
  font-size: 11px;
}

.ant-select-selector {
  height: 36px;
  padding: 2px 11px;
  border-radius: 4px;
}
.ant-btn-primary[disabled] {
  background: var(--border);
  color: var(--gray1);
}
.ant-form-item-label {
  width: 300px;
  text-align: left;
}

.tnc-pp {
  display: flex;
  justify-content: center;
  .content {
    h2 {
      font-size: 30px;
    }
    padding: 20px 40px 20px 40px;
    width: 50vw;
  }
}

.feature_rank_tooltip {
  position: absolute;
  top: -23px;
  right: 0px;
  color: var(--text-color);
}
.ant-popover-inner-content {
  color: var(--text-color);
}
.ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-topLeft > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-topRight > .ant-popover-content > .ant-popover-arrow {
  border-color: transparent var(--background) var(--background) transparent;
}
.ant-input[disabled] {
  color: var(--border);
}
.onboard-form .anticon {
  color: var(--text-color);
}
.caret {
  margin-left: 5px;
  color: var(--text-color);
  font-weight: normal;
  font-size: 14px;
}

.title-sec {
  display: flex;
  div:nth-child(1) {
    border-color: var(--primary-color);
    svg {
      fill: var(--primary-color);
    }
  }
  div:nth-child(2) {
    left: 0;
    border: none;
    box-shadow: var(--shadow);
    width: 300px;
    background: var(--background);
    svg {
      fill: var(--text-color);
    }
  }
  input {
    margin-left: 5px;
  }
}
.ant-radio,
.ant-radio-wrapper {
  color: var(--text-color);
}

.active-and-sort {
  display: flex;
  justify-content: space-between;
  align-items: end;
  .counts-active {
  }
  .sort-by {
    display: flex;
    flex-direction: row;
  }
  .sort-select {
    margin-right: 82px;
    display: flex;
    flex-direction: column;
  }
}

.coming-soon {
  position: absolute;
  left: 17px;
  top: -11px;
  height: 25px;
  width: 114px;
  background: var(--primary-color);
  padding-left: 9px;
  /* transform: rotate(45deg); */
  color: var(--background);
}
