.number-tile {
  border-radius: 4px;
  height: 90px;
  width: 268px;
  // box-shadow: var(--shadow);
  // -webkit-box-shadow: var(--shadow);
  // -moz-box-shadow: var(--shadow);
  color: var(--text-color);
  background: var(--background);
  border: 2px solid var(--border);
  display: flex;
  flex-direction: column;
  span:first-child {
    font-weight: bold;
    font-size: 20px;
  }
  padding: 10px;
  justify-content: space-around;
  span:last-child {
    font-size: 14px;
  }
}
