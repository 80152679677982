.user-list {
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
  .type{
    position: relative;
    top: -22px;
    left: 48px;
  }
}
