.gen-container {
  h1 {
    font-family: "Lato";
    font-size: 37px;
    margin-bottom: 0;
  }
  .sub-head {
    font-family: "Lato";
    font-size: 20px;
    color: var(--gray1);
    margin-bottom: 0;
  }
  display: flex;
  height: 90vh;
  flex-direction: row;
  .banner {
    background-image: url("./assets/Technology.svg");
    background-size: contain;
    background-repeat: no-repeat;
    width: 200px;
    flex: 1;
  }
  .form {
    flex: 1;
    .ant-row {
      // align-items: baseline;
    }
  }
}
