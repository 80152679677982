@mixin primaryLink {
  color: #ff9e00;
  font-size: 20px;
  font-weight: bold;
}

.pi-dashboard {
  display: flex;
  flex-direction: column;
  width: 100%;
  .contents-pf {
    min-height: 65vh;
    display: flex;
    .modules {
      // border: 1px solid red;
      //border-right: solid 1px rgba(153, 168, 191, 0.50);
      padding-right: 37px;
      margin-top: 50px;
      display: flex;
      flex-direction: column;
    }
    .kpis {
      flex: 3;
      margin-top: 50px;
      // border: 1px solid green;
      display: flex;
      // justify-content: space-evenly;
      flex-direction: column;
      .card-holder {
        margin-right: 20px;
      }
      .row-1 {
        display: flex;
        justify-content: space-between;
      }
    }
    .scores {
      flex: 1;
      // border: 1px solid blue;
    }
  }
  .add-wrap {
    width: 247px;
    float: left;
    height: 136px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 54px;
    .large {
      height: 100px;
      width: 100px;
    }
    .btx {
      display: flex;
      flex-direction: column;
      /* justify-content: center; */
      align-items: center;

      // font-family: Calibri;
      font-size: 14px;
      font-weight: bold;

      color: #575f6b;
      span {
        margin-top: 20px;
      }
    }
  }
  .pf-button-area {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .row-0 {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
    .score {
      height: 180px;
      width: 250px;
      border-left: solid 1px rgba(153, 168, 191, 0.5);
      padding: 0 30px 0 30px;
      .opp-rank {
        text-align: center;
        .rect1 {
          width: 100%;
          height: 58px;
          border-radius: 12px;
          box-shadow: 3px 3px 20px 0 rgba(36, 65, 93, 0.3);
          background-color: #ff9e00;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;
          font-size: 27px;
          font-weight: bold;
          // font-family: Calibri;
        }
      }
      .snd-line {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .rect {
          height: 70px;
          border-radius: 10px;
          box-shadow: -4px -4px 30px 0 #ffffff;
          background-color: #ff9e00;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;
          font-size: 27px;
          font-weight: bold;
          // font-family: Calibri;
        }
      }
    }
  }
  .row-01 {
    justify-content: space-between;
    .mf-area {
      width: 75%;
      .ant-row:nth-child(1) {
        span:nth-child(1) {
          @include primaryLink;
        }
        span:nth-child(2) {
          color: #575f6b;
          // font-family: Calibri;
          font-size: 20px;
          font-weight: bold;
        }
        margin-bottom: 20px;
      }
      .ant-row:nth-child(2) {
        span:nth-child(1) {
          @include primaryLink;
        }
        span:nth-child(2) {
          color: #575f6b;
          // font-family: Calibri;
          font-size: 22px;
          max-width: 600px;
          cursor: pointer;
        }
        .anticon {
          vertical-align: middle;
        }
      }
    }
  }
}

.add-feature {
  @include primaryLink;
  cursor: pointer;
}
.add-feature:hover {
  @include primaryLink;
  cursor: pointer;
  text-decoration: underline;
}

.neo-dependency {
  background: linear-gradient(90deg, #e6f7cb 0%, #e5e6ec 100%) !important;
  box-shadow: inset 13px 13px 26px #c4d2ad, inset -13px -13px 26px #ffffe9 !important;
}
.neo-cost {
  background: linear-gradient(
    90deg,
    rgba(245, 245, 218, 1) 0%,
    rgba(229, 230, 236, 1) 100%
  ) !important;
  box-shadow: inset 13px 13px 26px #d0d0b9, inset -13px -13px 26px #fffffb !important;
}

.neo-blue {
  background: rgb(218, 228, 252) !important;
  background: linear-gradient(
    90deg,
    rgba(218, 228, 252, 1) 0%,
    rgba(229, 230, 236, 1) 100%
  ) !important;
  box-shadow: inset 13px 13px 26px #b9c2d6, inset -13px -13px 26px #fbffff !important;
}

.neo-red {
  background: rgb(247, 222, 182) !important;
  background: linear-gradient(
    90deg,
    rgba(247, 222, 182, 1) 0%,
    rgba(229, 230, 236, 1) 100%
  ) !important;
  box-shadow: inset 13px 13px 26px #d2bd9b, inset -13px -13px 26px #ffffd1 !important;
}

.neo-green {
  box-shadow: inset 13px 13px 26px #add0aa, inset -13px -13px 26px #e9ffe6 !important;
  background: rgb(203, 245, 200) !important;
  background: linear-gradient(
    90deg,
    rgba(203, 245, 200, 1) 0%,
    rgba(229, 230, 236, 1) 100%
  ) !important;
}

.neo-color-input {
  min-width: auto !important;
  width: 215px !important;
  border-radius: 54px !important;
}
.inp-score {
  width: 25%;
  margin: 15px 0 15px 0;
  label {
    // font-family: Calibri;
    font-size: 18px;
    color: #273850;
  }
}

.wd-91 {
  width: 100%;
  .flex-x {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    max-width: 51vw;
  }
}
.input-score-label {
  color: #273850;
  // font-family: Calibri;
  font-size: 20px;
  font-weight: bold;
}
.top-head-features {
  display: flex;
  justify-content: space-between;
  margin-right: 30px;
  align-items: center;
  button {
    font-weight: bold;
  }
}
