.report-view {
  .ant-table-tbody > tr > td {
    border-left: 1px solid var(--border);
    border-bottom: 1px solid var(--border);
  }
  table {
    // border: 1px solid var(--border);
    tr {
      td {
        background: var(--background);
        // color: var(--text-color);
        color: #030303;
      }
    }
    thead {
      tr {
        th {
          padding: 5px;
          font-size: 14px;
          background: var(--hover-background);
          color: var(--text-color);
          font-weight: bold;
          border-bottom: 1px solid var(--gray1);
          height: 60px;
        }
        td {
          //   padding: 5px 10px;
          background: var(--hover-background);
          font-weight: bold;
          // color: var(--text-color);
          color: #030303;
        }
        .inps {
          // width: 80px;
          //   padding: 5px 10px;
        }
      }
    }
    tbody {
      td,
      .inp-per {
        padding: 5px 10px;
      }
    }
  }
}
