.kpi-list {
  width: 300px;
  min-height: 400px;
  margin-left: 40px;
  border-right: 1px solid var(--gray2);
  .tile {
    width: 265px;
    height: 86px;
    padding: 9px 12px 8px 20px;
    box-shadow: var(--shadow);
    background-color: var(--background);
    div:first-child {
      span:first-child {
        font-size: 28px;
        color: var(--primary-color);
      }
      span:last-child {
        margin-left: 10px;
        font-size: 20px;
        color: var(--text-color);
      }
    }
    div:last-child {
      span:first-child {
        color: var(--primary-color);
      }
      span:last-child {
        margin-left: 10px;
        color: var(--gray1);
      }
    }
  }
  .the-list {
    margin-top: 30px;
    padding-left: 20px;
    li.active {
      color: var(--primary-color);
      font-weight: bold;
      .chevron {
        display: block !important;
      }
    }
    li {
      cursor: pointer;
      div {
        width: 88%;
        display: flex;
        justify-content: space-between;
      }
      margin-bottom: 10px;
      p {
        color: var(--gray1);
        font-size: 12px;
        font-weight: normal;
      }
    }
  }
  .chevron {
    display: none;
  }
}
.kpi-list:last-child {
  border-right: none;
}
