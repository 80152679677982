.ip-container {
  width: 100%;
  .ip-head {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .neo-select {
      width: 300px;
    }
  }
  .ip-bodyarea {
    margin-top: 20px;
    .number-tile {
      margin-bottom: 20px;
    }
  }
  .ms-head {
    font-size: large;
    font-weight: bold;
    color: #575f6b;
  }
  .dv {
    border-top: none;
    border-bottom: solid 1px rgba(153, 168, 191, 0.5);
  }
  .modules {
    display: flex;
    flex-direction: row;
    height: 500px;
    .mt {
      flex: 1;
      .head {
        color: var(--text-color);
        // font-size: 16px;
        text-align: center;
        // box-shadow: var(--shadow);
        width: 120px;
        // height: 35px;
        // border: 1px solid var(--gray2);
        display: flex;
        align-items: center;
        justify-content: center;
        height: 36px;
        padding: 10.5px 13.1px 10.5px 13.1px;
        box-shadow: 0 4px 8px 0 rgba(44, 39, 56, 0.08);
        border: solid 1px #dbe2ea;
        background-color: var(--background);
        margin-bottom: 15px;
        margin-right: 15px;
      }
    }
  }
  .number-tile {
    width: 295px;
  }
}

.ip-button {
  border-radius: 10px;
  background: #edeff6;
  box-shadow: 3px 2px 12px 0px #bfbfbf, -20px -20px 60px #ffffff;
  height: 45px;
  padding: 10px;
  padding-left: 20px;
  width: 200px;
  margin: 15px;
  display: flex;
  justify-content: space-between;
}
