.snapshot {
  .snap-flex {
    display: flex;
    flex-direction: column;
    .number-snaps {
      flex: 1;
      //   height: 70vh;
      flex-wrap: wrap;
    }
    .number-snaps {
      display: flex;
      padding-top: 30px;
    }
    .trends {
      flex: 6;
      display: flex;
      .top-initiatives {
        flex: 3;
        min-height: 600px;
        flex-direction: row;
        display: flex;
        flex-wrap: wrap;
        // padding: 15px;
      }
      .assessment-trends {
        flex: 6;
        min-height: 600px;
        flex-wrap: wrap;
      }
    }
  }
  .subs-tile {
    height: 200px;
    width: 200px;
    // margin-right: 23px;
    h2,
    label,
    a {
      font-family: "Lato";
    }
  }
  .top-initiative {
    height: 300px;
    width: 200px;
    h2,
    label,
    a {
      font-family: "Lato";
    }
  }
}
