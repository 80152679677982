.assessment-trends {
  display: flex;
  flex-direction: column;
  .header-and-project {
    h2 {
      font-family: "Lato";
      font-size: 27px;
    }
    .select-box-project {
      display: flex;
      flex-direction: column;
    }
  }
  .bar-and-details {
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    .bar {
      flex: 1.3;
      display: flex;
      flex-direction: column;
      padding-right: 31px;
      border-right: 1px dotted var(--gray1);
      align-items: center;
      justify-content: center;
    }
    .details {
      padding-left: 30px;
      padding-right: 30px;
      flex: 1;
      display: flex;
      flex-direction: column;
      .details-header-and-pie {
        display: flex;
        justify-content: space-between;
        .info {
          display: flex;
          flex-direction: column;
          h3 {
            font-family: "Lato";
            font-size: 20px;
            margin: 0;
          }
        }
        .pie {
        }
      }
      .numbers-snap {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        .ass-tilex-tile {
          display: flex;
          flex-direction: column;
          height: 120px;
          width: 191px;
          justify-content: space-around;
          margin-top: 20px;
          h2 {
            font-size: 25px;
          }
        }
      }
    }
    .legends-bar {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      .legend-s {
        display: flex;
        flex-direction: row;
        // width: 100%;
        align-items: center;
        label {
          margin-left: 5px;
          font-size: small;
          margin-right: 10px;
        }
      }
    }
  }
}
