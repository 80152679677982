.feature-rank{
    .dash-area{
        display: flex;
        .menu{
            flex: 1;
        }
        .inputs{
            flex: 5;
        }
    }
    .the-list{
        margin-top: 10px;
        padding-left: 20px;
        li.active{
            color: var(--primary-color);
            font-weight: bold;
            .chevron{
                display: block !important;
            }
        }
        li{
            cursor: pointer;
            div{
                width: 88%;
                display: flex;
                justify-content: space-between;
            }
            margin-bottom: 10px;
        }
    }
    .inputs{
        margin-top: 48px;
        margin-left: 20px;
        .number-tiles{
            display: flex;
            margin-top: 10px;
            .number-tile{
                width: 212px;
                margin-right: 20px;
            }
        }
    }
    .chevron{
        display: none;
    }
}