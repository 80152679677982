.neo-collaspe{
    .ant-menu
    .ant-menu-sub,
    .ant-menu-inline{
        background: transparent;
       
    }
    .ant-menu-submenu-title{
        border-radius: 15px;
        background-image: linear-gradient(279deg, #f2f3f6 124%, #e5e6ec -77%);
        box-shadow:  8px 5px 20px #cecfd1, -8px -6px 19px #ffffff;
    }
    .ant-menu-submenu{
        margin: 20px 0 20px 0;
    }
    .ant-menu-submenu-arrow{
        border-radius: 117px;
        background: #F2F3F6;
        box-shadow: 3px 3px 6px #cecfd1, -3px -3px 6px #ffffff;
        height: 25px;
        width: 25px !important;
        top: 13px !important;
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: baseline;
    }
    .ant-menu-vertical .ant-menu-item::after, .ant-menu-vertical-left .ant-menu-item::after, .ant-menu-vertical-right .ant-menu-item::after, .ant-menu-inline .ant-menu-item::after {
        border-right: 3px solid #faad14;
    }
    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
        background-color: transparent;
        font-weight: bold;
        color: #000;
    }
    .ant-menu-submenu-selected
    {
        color: #575f6b;
        .ant-menu-submenu-title{
            font-weight: bold;
        }
        
        
    }
    .ant-menu-item:hover, .ant-menu-item-active, .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open, .ant-menu-submenu-active, .ant-menu-submenu-title:hover {
        color: #faad14;
    }

    .ant-menu-submenu-title:hover {
        color: #faad14;
    }
    .ant-menu-submenu-title{
        min-height: 50px;
        display: flex;
        align-items: center;
        // font-family: Calibri;
        font-size: 14px;
        font-weight: bold;
        color: #575f6b;
    }
}