.subs-tile {
  border-radius: 4px;
  height: 250px;
  width: 270px;
  -webkit-box-shadow: 11px 9px 49px 0px #ebebeb;
  -moz-box-shadow: 11px 9px 49px 0px #ebebeb;
  box-shadow: 11px 9px 49px 0px #ebebeb;
  display: flex;
  flex-direction: column;
  div {
    display: flex;
    flex-direction: column;
    span {
      font-weight: bold;
      font-size: 20px;
    }
    small {
      font-size: small;
      color: #75757d;
    }
  }
  padding: 10px 20px 10px 20px;
  justify-content: space-around;
  span:last-child {
    font-size: 20px;
  }
  .images {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  margin-right: 30px;
  margin-bottom: 30px;
  .new-subs,
  .small-txt {
    color: var(--text-color);
  }
}

.subs-tile:hover {
  background-color: #f4e9dc;
  -webkit-box-shadow: 11px 9px 49px 0px #f4e9dc;
  -moz-box-shadow: 11px 9px 49px 0px #f4e9dc;
  box-shadow: 11px 9px 49px 0px #f4e9dc;
  //   color: #fff;
  cursor: pointer;
}

@media (prefers-color-scheme: dark) {
  .subs-tile {
    box-shadow: 11px 9px 49px 0px #1b1817;
  }
  .subs-tile:hover {
    background-color: #151414;
    box-shadow: 11px 9px 49px 0px #1a1717;
    cursor: pointer;
  }
}

.mods {
  color: var(--text-color);
}
.head-text {
  span {
    color: var(--text-color);
  }
}

.custom-add {
  span {
    font-size: inherit !important;
  }
}
