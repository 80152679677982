.subscription-page {
  padding: 25px 60px 39px 60px;
  .numbers {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    .number-tile {
      margin-right: 30px;
    }
  }
  span.small-txt {
    color: var(--text-color);
  }
  .subscriptions {
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 390px) {
  .subscription-page {
    padding: 0 10px 10px 27px;
  }
}
