@import "../../../styles/variables.scss";

.mod-card {
  height: 80px;
  width: 208px;
  .selected {
    color: #079507;
  }
  box-shadow: var(--shadow);
  background: var(--background);
  padding: 10px;
  margin-right: 10px;
  color: var(--text-color);
  h4{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.mod-card:hover {
  background-color: var(--hover-background);
  box-shadow: var(--shadow);
  cursor: pointer;
}
