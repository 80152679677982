@media (prefers-color-scheme: dark) {
  :root {
    --primary-color: #0789d8;
    --background: #21211f;
    // --text-color: #fff;
    --text-color: #cccbcb;
    --gray1: #6b93bb;
    --gray2: #2a445e;
    --shadow: 11px 9px 49px 0px #1e1b1a;
    --shadow-hover: 11px 9px 49px 0px #272321;
    --background-hover: #1a283c;

    --placeholder-background: rgba(26, 40, 65, 1);
    --placeholder-text-color: #6b93bb;

    --border: #334e68;
    --hover-background: #37363e;
  }
}

@media (prefers-color-scheme: light) {
  :root {
    --background: #fff;
    --text-color: #273850;
    --gray1: #8c8c8c;
    --gray2: #f1efef;
    --primary-color: #0789d8;
    --shadow: 11px 9px 49px 0px #ebebeb;
    --shadow-hover: 11px 9px 49px 0px #f4e9dc;
    --background-hover: #f4e9dc;

    --placeholder-background: #fff;
    --placeholder-text-color: #6b93bb;
    --border: #dddada;
    --hover-background: #f4e9dc;
  }
}
