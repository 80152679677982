.launchpad {
  .ip-load {
    .ant-skeleton {
      width: 60%;
      margin-right: 40px;
    }
  }
  .actions-buttons {
    span {
      color: var(--primary-color) !important;
    }
  }
  .actions-buttons:hover {
    span {
      color: var(--background) !important;
    }
  }
}
