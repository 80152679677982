.p-form-area {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .p-fl-item {
    padding: 10px 0 10px 0;
    width: 45%;
    max-width: 340px;
    label {
      display: inline-block;
      text-transform: lowercase;
    }
    label:first-letter {
      text-transform: uppercase;
    }
  }
}
.pf-button-area {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
