.programs-x {
  width: 100%;
  margin: auto;
  .ant-table {
    margin-top: 0px;
  }
  .link-button {
    color: #575f6b;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: Calibri;
    font-size: 14px;
  }
  h1 {
    // font-family: Calibri;
    font-size: 40px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: left;
    color: #273850;
  }
  .score {
    width: 100px;
    height: 51px;
    border-radius: 30px;
    background-color: #ff9e00;
    color: #ffffff;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    // font-family: Calibri;
    font-size: 25px;
  }
}

.add-program {
  width: 100%;

  .form-area {
    display: flex;
    margin: 0 60px 0 60px;
    justify-content: space-around;
    flex-direction: row;
    .fl-item {
      display: flex;
      flex-direction: column;
      label {
        font-size: 18px;
        color: #273850;
        margin-left: 20px;
      }
      .inp-group {
        margin-bottom: 30px;
      }
    }
  }
}

.program-flex {
  width: 100%;
  // height: 90vh;
  display: flex;
  justify-content: space-around;
}
.program-list-nx {
  .ant-table {
    max-height: 66vh;
    overflow: auto;
  }
}
