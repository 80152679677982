.main_table {
  table {
    // border: 1px solid var(--border);
    tr {
      td {
      }
    }
    thead {
      tr {
        th {
          padding: 10px;
          text-align: center;
          font-size: 14px;
          background: var(--hover-background);
          // color: var(--text-color);
          font-weight: normal;
          border: 1px solid var(--border);
        }
        td {
          padding: 5px 10px;
          background: var(--hover-background);
          font-weight: bold;
          color: var(--text-color);
        }
        .inps {
          // width: 80px;
          padding: 5px 10px;
        }
      }
    }
    tbody {
      td,
      .inp-per {
        padding: 5px 10px;
        border: 1px solid var(--border);
      }
    }
    .PROGRAM_row {
      background-color: var(--gray2);
      td:first-child {
        font-weight: bold;
      }
    }
    .INITIATIVE_row {
    }
    .OPPORTUNITY_row {
    }
    .FEATURE_row {
    }
    .score {
      font-weight: bold;
      font-size: 16px;
    }
  }
}

@media (prefers-color-scheme: dark) {
  .main_table {
    table {
      thead {
        tr {
          th {
            color: #fff;
          }
          td {
            color: #fff;
          }
          .inps {
            padding: 5px 10px;
          }
        }
      }
      tbody {
        tr {
          td,
          .inp-per {
            border: 1px solid #21384e;
          }
        }
      }
    }
  }
}
