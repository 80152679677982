@import "../../../styles/variables.scss";

.nav-bar {
  min-height: 65px;
  display: flex;
  padding: 0 10px 0 10px;
  justify-content: space-between;
  align-items: center;
  // position: fixed;
  width: 100%;
  z-index: 100;
}

.app-logo-dark {
  display: none;
}

@media (prefers-color-scheme: dark) {
  .app-logo-dark {
    display: block;
  }
  .app-logo-light {
    display: none;
  }
}

@media (prefers-color-scheme: light) {
  .app-logo-dark {
    display: none;
  }
  .app-logo-light {
    display: block;
  }
}
