.gen-init {
  .kpi-list .the-list li p {
    margin-bottom: 0;
  }
  .kpi-list .the-list li:hover {
    background-color: var(--background-hover);
  }
  .no-programs {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 300px;
    padding: 20px;
    text-align: center;
  }
}
