.card {
  background: #fff;
  // background-image: linear-gradient(315deg, #f2f3f6 96%, #e5e6ec -19%);
  padding: 70px;
}

.cards .row {
  width: 500px;
  margin-bottom: 0;
}

.cards .row .col:not(:last-child) {
  padding-right: 20px;
}
.cards .card {
  box-shadow: var(--shadow);
  background: var(--background);
  border-radius: 8px;
  min-height: 380px;
  display: grid;
}

.cards.container {
  // width: 100%;
  // max-width: none;
  display: flex;
  flex: 1;
  justify-content: center;
}

.Aligner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  // background-image: url('../../assets/images/BAckground-01.png');
  background-size: cover;
  background-color: var(--background);
}

.Aligner-item {
  width: 493px !important;
  // height: 567px;
}

.Sign-in {
  width: 75px;
  height: 27px;
  font-size: 26px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: normal;
  text-align: left;
  color: var(--text-color);
  font-family: "GFS Didot";
}

.Sign-in-to-start-your-assessment {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #171717;
}

.invalid {
  margin-top: 20px;
  margin-bottom: 20px;
  span {
    color: red;
  }
}

.area {
  button {
    min-width: 100px;
  }
}
.input-items {
  margin-top: -40px;
  width: 200px;
  float: left;
  /* form starting stylings ------------------------------- */
  .group {
    position: relative;
    margin-bottom: 30px;
  }
  input {
    font-size: 18px;
    display: block;
    width: 360px;
    border: none;
    border-bottom: 1px solid #757575;
    padding-bottom: 10px;
    background: transparent;
    padding-left: 0px;
  }
  input:focus {
    outline: none;
  }

  /* LABEL ======================================= */
  label {
    color: #000;
    font-size: 16px;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    // left:5px;
    top: 10px;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
  }

  /* active state */
  input:focus ~ label,
  input:valid ~ label {
    top: -20px;
    font-size: 14px;
    color: #5264ae;
  }

  /* BOTTOM BARS ================================= */
  .bar {
    position: relative;
    display: block;
    width: 360px;
  }
  .bar:before,
  .bar:after {
    content: "";
    height: 1px;
    width: 0;
    bottom: 1px;
    position: absolute;
    background: #5264ae;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
  }
  .bar:before {
    left: 50%;
  }
  .bar:after {
    right: 50%;
  }

  /* active state */
  input:focus ~ .bar:before,
  input:focus ~ .bar:after {
    width: 50%;
  }

  /* HIGHLIGHTER ================================== */
  .highlight {
    position: absolute;
    height: 60%;
    width: 100px;
    top: 25%;
    left: 0;
    pointer-events: none;
    opacity: 0.5;
  }

  /* active state */
  input:focus ~ .highlight {
    -webkit-animation: inputHighlighter 0.3s ease;
    -moz-animation: inputHighlighter 0.3s ease;
    animation: inputHighlighter 0.3s ease;
  }

  .checkbox {
    float: left;
    width: 20px;
    margin-top: 4px;
    /* position: absolute; */
    padding: 0;
    margin-left: -4px;
  }

  /* ANIMATIONS ================ */
  @-webkit-keyframes inputHighlighter {
    from {
      background: #5264ae;
    }
    to {
      width: 0;
      background: transparent;
    }
  }
  @-moz-keyframes inputHighlighter {
    from {
      background: #5264ae;
    }
    to {
      width: 0;
      background: transparent;
    }
  }
  @keyframes inputHighlighter {
    from {
      background: #5264ae;
    }
    to {
      width: 0;
      background: transparent;
    }
  }
}

.copy {
  margin-top: 42px;
  p {
    font-stretch: normal;
    letter-spacing: normal;
    text-align: left;
  }
}

.leff-items {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-left: 200px;
  div {
    h1:nth-child(1) {
      color: #ff9e00;
      font-size: 100px;
      margin-bottom: 0;
    }
    h4:nth-child(2),
    h4:nth-child(3) {
      color: #999999;
      font-size: 60px;
      line-height: 40px;
    }
  }
}

.icon-block {
  display: flex;
  justify-content: space-between;
}

.welcome-head {
  font-family: Lato;
  font-size: 35px;
  text-align: left;
  color: var(--text-color);
}
.welcome-head-text {
  // text-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  // font-family: Didot;
  // font-size: 110px;
  // text-align: left;
  // color: var(--primary-color);
  // margin-top: -25px;
  height: 80px;
}

.by-hc3 {
  display: flex;
  justify-content: center;
  align-items: center;
  // margin-top: -28px;
  div {
    font-size: 23px;
    margin-right: 10px;
  }
}

@media (prefers-color-scheme: dark) {
  .brand-logo {
    content: url(../../assets/icons/Honeycom3_Light_Logo.png);
    height: 30px;
  }
  .welcome-head-text {
    // content: url(https://hc3images.s3.us-east-2.amazonaws.com/Nectar+Logo+Light.png);
    content: url("./pp2.png");
  }
}

@media (prefers-color-scheme: light) {
  .brand-logo {
    content: url(../../assets/icons/Honeycom3_Full_Logo.png);
    height: 30px;
  }
  .welcome-head-text {
    // content: url(https://hc3images.s3.us-east-2.amazonaws.com/Nectar+Logo+Dark.png);
    content: url("./pp.png");
  }
}
