.maturity-results{
    .maturity-kpi{
        height: 203px;
        box-shadow: var(--shadow);
        padding: 20px;
        display: flex;
        align-items: center;
        .kpi{
            width: 100px;
            margin: 0px 30px 0px 30px;
            h1{
                margin-bottom: 0;
            }
            span{
                color: var(--text-color);
            }
        }
    }
    .no-result{
        .empty-hm{
            height: 64vh;
        }
    }
}