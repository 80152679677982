.tile{
    width: 292px;
    height: 253px;
    padding: 24px 6px 11px 20px;
    box-shadow: var(--shadow);
    background-color: var(--background);
    margin-right: 20px;
    margin-top:20px;
    .title{
        font-size: 20px;
        font-weight: bold;
        color: var(--text-color);
    }
    .row__tile{
        margin-bottom: 5px;
        span:first-child{
            font-size: 16px;
            color: var(--text-color);
        }
        span:last-child{
            margin-left: 5px;
            font-size: 16px;
            color: var(--primary-color);
        }
    }
}


.tile:hover {
    background-color: var(--background-hover);
    -webkit-box-shadow: var(--shadow-hover);
    -moz-box-shadow: var(--shadow-hover);
    box-shadow: var(--shadow-hover);
    cursor: pointer;
  }