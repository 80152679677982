.mobile-banner {
  display: none;
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  //your styles here
  .Aligner {
    .leff-items {
      display: none;
    }
  }

  .mobile-banner {
    display: block;
    margin-bottom: 40px;
    .welcome-head {
      font-size: 20px;
    }
    .welcome-head-text {
      font-family: GFS Didot;
      font-size: 60px;
      color: var(--primary-color);
      margin-top: -15px;
    }

    .by-hc3 {
      margin-top: -29px;
      div {
        margin-top: 7px;
        font-size: 16px;
        margin-right: 5px;
      }
    }
  }
  .kpi-list {
    width: 184px;
  }
  .kpi-list .tile {
    width: 177px;
  }
  .kpi-list .tile div:first-child span:last-child {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .welcome-text {
    margin-top: -60px;
  }

  .create-project {
    .industry {
      margin-left: 100px;
    }
  }
  .empty-hm .illustration img {
    width: 254px;
  }
  // .projects-page{
  .empty-hm {
    height: 64vh;
  }
  .ant-slider-mark .ant-slider-mark-text {
    width: 100px;
    text-align: left;
  }
  .ant-slider-mark .ant-slider-mark-text:first-child {
    left: -5%;
  }
  .line-steps {
    min-width: 35px;
  }
}

@media print {
  @page {
    size: landscape;
  }
  .main_table {
    th,
    td {
      border: 1px solid var(--border);
    }
    //  display: none;
    .inps {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 60px;
    }
  }
}
