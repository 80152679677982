@import "../../../styles/variables.scss";

.empty-hm {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 80vh;
  padding: 0 65px 0 65px;
  .illustration {
    display: flex;
    justify-content: center;
    // flex: 2;
    img {
      width: 400px;
    }
  }
  .greeting {
    flex: 3;
    .user {
      border-bottom: 3px solid var(--primary-color);
    }
    margin-left: 80px;
  }
}
