.p:nth-child(odd) {
  //   background: red;
}

p:nth-child(even) {
  //   background: blue;
}

.time-chart {
  svg {
    // rect:nth-child(odd)[x="0"] {
    //   fill: var(--background);
    // }
    // rect:nth-child(even)[stroke="none"] {
    //   fill: var(--border);
    // }

    rect[fill="#e6e6e6"],
    rect[fill="#ffffff"] {
      fill: var(--background);
    }

    path[stroke="#ffffff"],
    path[stroke="#e6e6e6"] {
      stroke: var(--border);
    }
    text {
      fill: var(--text-color);
      font-family: "Lato";
    }
    path[stroke="#b7b7b7"] {
      stroke: var(--border);
    }
    rect[stroke="none"] {
      //   fill: var(--primary-color);
      rx: 10;
    }
  }
}
