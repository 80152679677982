.hr1 {
  width: 100%;
  position: absolute;
  top: 46%;
  border: none;
  border-top: 1px solid var(--border);
}
.hr2 {
  height: 57vh;
  position: absolute;
  left: 49%;
  border: none;
  border-left: 1px solid var(--border);
}

.bubble {
  position: absolute;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  .title {
    position: absolute;
    bottom: -21px;
    width: 100px;
    text-align: center;
    font-size: 12px;
  }
}

.bubble-chart {
  .top-left {
    position: absolute;
    top: 20px;
    left: 42px;
    color: var(--gray1);
  }
  .bottom-left {
    position: absolute;
    bottom: 20px;
    left: 42px;
    color: var(--gray1);
  }
  .top-right {
    top: 20px;
    position: absolute;
    right: 47px;
    color: var(--gray1);
  }
  .bottom-right {
    bottom: 20px;
    position: absolute;
    right: 47px;
    color: var(--gray1);
  }
  .title {
    color: var(--text-color);
  }
  width: 100%;
  display: flex;
  .flx22 {
    z-index: 0;
    width: 100%;
    .bg-flex {
      height: 50%;
      display: flex;
      flex-wrap: wrap;
      flex: 1;
      .row {
        flex: 1;
        width: 100%;
      }
      .child {
        flex: 1;
        height: 50%;
        border: 2px solid #fff;
      }
      .yellow {
        background: #ffefc1;
      }
      .red {
        background: #f6c3c3;
      }
      .green {
        background-color: rgb(189, 238, 189);
      }
    }
    .row1,
    .row2 {
      display: flex;
      width: 100%;
      display: flex;
      flex-direction: column;
    }
  }
}

.the-right-border {
  border-right: 2px solid var(--gray1);
  border-bottom: 2px solid var(--gray1);
}
.the-other-border {
  border-top: 2px solid var(--gray1);
}
.the-left-border {
  border-right: 2px solid var(--gray1);
}
