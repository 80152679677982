.mod-tile {
  border-radius: 4px;
  height: 250px;
  width: 280px;
  -webkit-box-shadow: 11px 9px 49px 0px #ebebeb;
  -moz-box-shadow: 11px 9px 49px 0px #ebebeb;
  box-shadow: 11px 9px 49px 0px #ebebeb;
  padding: 20px 20px 20px 20px;
  justify-content: space-around;
  .img {
    display: flex;
    justify-content: end;
  }
  margin-right: 20px;
  h2 {
    font-family: "Lato";
  }
}

.mod-tile:hover {
  background-color: var(--hover-background);
  -webkit-box-shadow: 11px 9px 49px 0px var(--hover-background);
  -moz-box-shadow: 11px 9px 49px 0px var(--hover-background);
  box-shadow: 11px 9px 49px 0px var(--hover-background);
  //   color: #fff;
  cursor: pointer;
}

@media (prefers-color-scheme: dark) {
  .mod-tile {
    box-shadow: 11px 9px 49px 0px #161719;
  }
  .mod-tile:hover {
    background-color: #37363e;
    box-shadow: 11px 9px 49px 0px rgb(54, 48, 47);
    cursor: pointer;
  }
}
