.roadmap {
  display: flex;
  flex-direction: column;
  height: 70vh;
  justify-content: space-between;
  width: 100%;
  .back-button {
    font-size: 30px;
    color: #99a8bf;
    margin-top: 10px;
    cursor: pointer;
  }
  .flex-row {
    display: flex;
    flex-direction: row;
    width: 100%;
    flex: 1;
    justify-content: space-between;
    .ls {
      flex: 5;
      display: flex;
    }
    .rght {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: end;
      align-items: center;
      span {
        color: #273850;
        font-size: 16px;
      }
      .download-icon {
        font-size: 40px;
        color: #0e1626;
      }
    }
  }
  .neo-select {
    width: 87px;
    margin-left: 30px;
  }
  .mid-part {
    flex: 9;
    // align-items: center;
    margin-top: 20px;
  }
  .ml-some {
    margin-left: 80px;
    display: flex;
    .titles,
    .legends {
      flex: 1;
    }
    .legends {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      margin-right: 30px;
    }
    div {
      color: #afafaf;
      font-size: 30px;
      // font-family: Calibri;
    }
  }
  .sel_field {
    display: flex;
    flex-direction: column;
    width: 178px;
  }
}
