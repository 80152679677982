.user-list-o {
  display: flex;
  img {
    height: 30px;
    border-radius: 50%;
    margin-right: 10px;
  }
  .info-o {
    display: flex;
    flex-direction: column;
  }
}
