.qs-page {
  .qs-box {
    display: flex;
    .menu-ques {
      flex: 1;
      border: 1px solid #ccc;
      height: 60vh;
    }
    .ques-set {
      flex: 4;
      height: 60vh;
      border: 1px solid #ccc;
    }
  }
  .check-row {
    padding: 6px 0 5px 0;
  }
  .edit-qs {
    .ant-checkbox-wrapper {
      display: flex;
      textarea {
        width: 40vw;
      }
    }
  }
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
}
