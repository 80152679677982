.timeline {
  width: 100%;
  display: flex;
  .y-axis {
    flex: 1.4;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .opp {
      div {
        transform: rotate(-90deg);
        // font-family: Calibri;
        font-size: 34px;
        color: #afafaf;
        line-height: 10px;
        /* position: fixed; */
        /* left: 207px; */
        width: 38px;
        margin-top: 124px;
      }
    }
    .high,
    .low {
      color: #575f6b;
      font-size: 20px;
      // font-family: Calibri;
    }
  }
  .neo-base {
    flex: 20;
    display: flex;
    flex-direction: column;
    .timeline {
      height: 60vh;
      width: 100%;
      /* border-radius: 37px; */
      display: flex;
      border: 1px solid var(--gray2);

      .internal-flexbox:last-child {
        border: none !important;
      }
      .internal-flexbox {
        flex: 1;
      }

      .internal-flexbox {
        border-right: solid 1px rgba(153, 168, 191, 0.5);
      }
    }
    .neo-legend {
      margin-top: 10px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .quater {
        flex: 1;
        color: #273850;
        font-size: 14px;
        // font-family: Calibri;
      }
    }
  }
}

.times {
  position: absolute;
  .x1 {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

.barX {
  width: 38vh;
  height: 15px;
  /* background: azure; */
  border-radius: 10px;
  // box-shadow: -1px 2px 6px 0 #33c7d4;
  // background-image: linear-gradient(to right, #0092b9 -7%, #33c7d4 95%);
  background: var(--primary-color);
}
.pop-card {
  width: 400px;
  color: var(--text-color);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  .grid {
    display: flex;
    width: 50%;
    padding: 5px;
    .box {
    }
    .text {
      margin-left: 5px;
    }
  }
}
