.flex-container {
  display: flex;
  flex-direction: row;
  .info-area {
    flex: 1;
  }
  .questions-area {
    flex: 1;
  }
  .neo {
    min-width: 170px;
  }
}
.pdd {
  .ant-select-multiple {
    width: 442px !important;
  }
  .title-sec {
    input {
      width: 300px;
      font-weight: bold;
      font-size: 16px;
    }
  }
}
